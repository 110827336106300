import LBAQRCodeScanner from '@/components/ui/scanner/LBAQRCodeScanner.vue';
import LBADialog from '../../components/ui/dialogs/LBADialog.vue';
export default {
  name: 'MainPage',
  data() {
    return {
      scannedResult: null,
      showQRModal: false
    };
  },
  components: {
    LBAQRCodeScanner,
    LBADialog
  },
  methods: {
    handleScanned(result) {
      this.scannedResult = result;
    }
  }
};